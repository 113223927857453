// AHN colors
$black: #0b0e0e;
$white: #ffffff;
$shadeGreen: #006d41;
$ghostGrey: #5f7c7c;
$darkNavy: #092941;
$mediumNavy: #084266;
$ahnGreen: #0cb161;
$ahnPink: #ca3c67;
$alertRed: #e52d27;
$togetherBlue: #003963;
$darkCyanBlue: #424649;
$splashBlue: #0078c1;
$darkBlue: #001446;
$blue: #00a2e2;
$darkGrey: #435656;
$gray: #707070;
$lightGrey: #f2f2f2;
$paperColor: #f1f4f4;
$lightGray: #f3f4f5;
$blackDarkGray: #37383C;
$mediumGrey: #e2e4e5;
$shadowGreen: #9cd5ba;
$polar: #f7fbfd;
$ahnWomenPink: #ca3d67;
$mist: #cdeadc;
$blush: #fce0d9;
$shadowGrey: rgba(0, 0, 0, 0.16);
$bridgeYellow: #fff689;
$ahn25MistGreen: #e6f5ee;
$ahnDishwaterGrey: #8e8e8e;
$ahnGray: #e2e9e9;
$space-desktop: 9rem;
$space-tablet: 6rem;
$space-mobile: 5rem;
$clear-arrow-size: 6px;
$clear-arrow-border-width: 0.5px;
$clear-border-width: $clear-arrow-border-width;

$tablet-min-width: 767px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;

@mixin sofia-pro-semi-bold {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@mixin sofia-pro-regular {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin sofia-pro-bold {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin roboto-bold {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
}
@mixin roboto-semi-bold {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@mixin roboto-regular {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
}
