@import '../../../styles/variables.scss';

#ahn-findcare {
  .google-map-ahn {
    .search-map-infowindow {
      width: 275px;
      padding-top: 16px;
      padding-left: 8px;
      .provider-contact-container .provider-practice-name{
          width: 70%;
      }
      @media screen and (max-width: $tablet-min-width) {
        width: 100%;
      }

      .provider-directions {
        @media screen and (max-width: $tablet-min-width) {
          padding-top: 10px;
        }
        a {
          color: #006d41;
          cursor: pointer;
          font-size: 16px;
          text-decoration: none;
          @media screen and (max-width: $tablet-min-width) {
            font-size: 12px;
          }
        }
      }

      .map-phone-direction {
        margin: 10px 0 5px 0;
        justify-content: space-between;
        align-items: center;
        display: flex;
        @media screen and (max-width: $tablet-min-width) {
          display: block;
        }
      }
    }
    .provider-contact-container {
      height: fit-content;
      .provider-practice-name {
        padding-bottom: 8px;
        font-size: 16px;
        @media screen and (max-width: $tablet-min-width) {
          font-size: 14px;
        }
        color: #001446;
      }
      .provider-phone {
        color: #0078c1;
        font-size: 16px;
        @media screen and (max-width: $tablet-min-width) {
          font-size: 12px;
          padding-top: 10px;
        }
      }
    }
  }
}
