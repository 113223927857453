@import '../../../styles/variables.scss';

#ahn-findcare {
  .sort-bar {
    .mat-mdc-option {
      padding: 0 10px;
    }

    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding: 14px 0 14px 0;
    }

    .mat-mdc-select-arrow {
      color: $togetherBlue;
    }

    .mat-mdc-select-value {
      color: $darkBlue;
    }

    .mat-mdc-select-value-text,
    .mat-mdc-select-placeholder {
      color: $darkBlue;
      @include roboto-regular;
      font-size: 15px;
    }

    mat-label {
      color: $darkBlue;
    }

    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-appearance-outline,
    .mat-mdc-form-field-outline-thick {
      color: $togetherBlue;
    }

    .mat-mdc-form-field-appearance-outline,
    .mat-mdc-form-field-outline {
      color: $togetherBlue;
    }

    .mat-mdc-form-field-appearance-outline.mat-focused,
    .mat-mdc-form-field-outline-thick {
      color: $togetherBlue;
    }

    .mat-mdc-select-panel {
      left: 0;
    }

    .mat-mdc-select-panel {
      background: $white;
    }

    .mat-mdc-option.mat-active {
      color: $darkBlue;
    }

    .mat-primary {
      .mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled) {
        color: $togetherBlue;
      }

      .mat-mdc-option.mat-mdc-option-disabled {
        color: lightgray;
      }
    }

    .mat-mdc-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline-thick {
      color: $shadeGreen;
    }

    .mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
      color: $darkBlue;
    }

    .mat-mdc-option-text,
    .mat-mdc-form-field {
      @include roboto-regular;
      font-size: 15px;
    }
  }
  .cdk-overlay-container {
    .ng-trigger.ng-trigger-transformPanel.mat-mdc-select-panel.mat-primary {
      min-width: calc(100% + 26px) !important;
    }
  }
}
