@import '../../../styles/variables.scss';

#ahn-findcare {
  .distance-filter-conatainer,
  .distance-filter-mobile {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }
    .distance-filter {
      .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
        color: $togetherBlue;
      }
      .mat-mdc-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline-thick {
        color: $shadeGreen;
      }
    }
  }
}
