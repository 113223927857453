@import '../../../styles/variables.scss';

#ahn-findcare {
  .new-gender-filter-container {
    .mdc_radio-outer__circle {
      border-color: #8996a1;
    }

    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
      border-color: $togetherBlue;
    }

    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__inner-circle {
      background-color: $togetherBlue;
      border-color: $togetherBlue;
    }

    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple {
      background-color: $white;
    }

    .mat-mdc-radio-container:hover .mat-radio-persistent-ripple {
      opacity: 0;
    }

    .mat-mdc-radio-container .mat-radio-persistent-ripple {
      opacity: 0;
    }

    .mdc-form-field > label {
      color: $darkBlue;
      font-family: roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
