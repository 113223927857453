@import './variables.scss';
// Mobile First
body {
  @include roboto-regular;
  background-color: $white;
  color: $darkBlue;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-touch-callout: default;
  -webkit-overflow-scrolling: touch;
}

img {
  width: 100%;
  display: block;
  margin: auto;
}

li {
  @include roboto-regular;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: $darkBlue;
}

ul {
  @include roboto-regular;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
  color: $darkBlue;
}

ol {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 100;
  margin-bottom: 1rem;
  color: $darkBlue;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  @include sofia-pro-semi-bold;
}

p {
  @include roboto-regular;
}

h1,
.h1 {
  font-size: 32px;
  color: $togetherBlue;
  letter-spacing: -1.28px;
  line-height: 1.1;
  margin: 0;
}

h2,
.h2 {
  font-size: 26px;
  color: $togetherBlue;
  letter-spacing: -0.72px;
  line-height: 1.1;
}

h3,
.h3 {
  font-size: 22px;
  color: $togetherBlue;
  letter-spacing: -0.4px;
  line-height: 1.1;
}

p {
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0px;
  line-height: 1.4;
  color: $darkBlue;
}

@media screen and (max-width: $tablet-min-width) {
  p.bold-first-paragraph {
    @include sofia-pro-semi-bold;
    font-size: 16px;
    letter-spacing: -0.72px;
  }
}

@media screen and (min-width: $tablet-min-width) {
  p.bold-first-paragraph {
    @include sofia-pro-semi-bold;
    font-size: 20px;
    letter-spacing: -0.72px;
  }
}

.ahn-dmxfad {
  .container {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: none;
  }
  .container-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: $tablet-min-width) {
  .button-radius-landing-page,
  .button-radius {
    width: 1px !important;
  }
}

// tablet 768 - 992
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  h1,
  .h1 {
    font-size: 36px;
    letter-spacing: -1.44px;
    line-height: 1.1;
  }

  h2,
  .h2 {
    font-size: 28px;
    letter-spacing: -0.78px;
    line-height: 1.1;
  }

  h3,
  .h3 {
    font-size: 24px;
    letter-spacing: -0.44px;
    line-height: 1.1;
  }

  p {
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 1.3;
  }

  .ahn-dmxfad {
    .container {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}

// desktop size
@media (min-width: $desktop-min-width) {
  h1,
  .h1 {
    font-size: 56px;
    letter-spacing: -2.8px;
    line-height: 1.1;
  }

  h2,
  .h2 {
    font-size: 34px;
    letter-spacing: -1.36px;
    line-height: 1.2;
  }

  h3,
  .h3 {
    font-size: 24px;
    letter-spacing: -0.72px;
    line-height: 1.1;
  }

  p {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.3;
  }

  .ahn-dmxfad {
    .container {
      max-width: 1200px;
      padding-left: 83px;
      padding-right: 83px;
    }
    .container-body {
      max-width: 1366px;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
    }
    .filters-outer-container {
      padding: 16px 16px 16px 40px;
    }
  }
}

atlas-landing-page {
  flex: 1 1 auto;
  background-color: $ahn25MistGreen;
}

.mat-drawer-content {
  overflow: hidden !important;
}
