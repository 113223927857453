@import '../../../styles/variables.scss';

#ahn-findcare {
  .mat-mdc-button .mdc-button__label {
    z-index: 0;
  }
  .dmxfad-search-container {
    .mdc-notched-outline,
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notc,
    .mdc-notched-outline__trailing {
      border-color: transparent;
    }
    .mat-mdc-form-field-infix {
      flex: 1;
      display: flex;
      padding: 0;
      border-top: 0;
      height: 48px;
    }

    .mdc-text-field {
      padding: 0;
    }
    .mat-mdc-option {
      color: $darkBlue;
      @include roboto-regular();
      font-size: 16px;
    }

    .mat-mdc-input-element {
      caret-color: $darkBlue;
    }

    .mat-mdc-button-focus-overlay {
      background-color: transparent;
    }
  }
  .cdk-overlay-container .mat-mdc-autocomplete-panel {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
    border-top: 1px solid $mediumGrey;
    max-height: 325px !important;
  }
  .mat-mdc-autocomplete-panel {
    background-color: $white;
  }

  .mat-mdc-option:hover {
    background-color: $paperColor;
  }
}
