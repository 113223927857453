@import '../../../styles/variables.scss';

#ahn-findcare {
  .conditions-root-container {
    .mat-mdc-form-field-label-wrapper {
      top: -0.99em;
    }
    .mat-mdc-form-field.mat-form-field-invalid.ng-touched.mat-form-field-hide-placeholder {
      .mat-mdc-form-field-outline.mat-form-field-outline-thick {
        opacity: 0;
      }
      .mat-mdc-form-field-outline {
        opacity: 1;
      }

      &:hover {
        .mat-mdc-form-field-outline.mat-form-field-outline-thick {
          opacity: 1;
        }
        .mat-mdc-form-field-outline {
          opacity: 0;
        }
      }
    }

    .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid
      .mat-mdc-form-field-outline-thick {
      color: $togetherBlue;
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: #00000061;
    }

    .mat-focused.mat-mdc-form-field-appearance-outline
      .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick {
      color: $shadeGreen;
    }

    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
      color: $togetherBlue;
    }

    .mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
      color: $togetherBlue;
    }

    .mat-mdc-form-field.mat-mdc-form-field-invalid.mat-mdc-form-field-hide-placeholder
      .mat-mdc-form-field-label {
      color: rgba(0, 0, 0, 0.6);
    }

    .mat-mdc-form-field.mat-mdc-form-field-invalid.mat-focused {
      .mat-mdc-form-field-label {
        color: $shadeGreen;
      }
    }

    .mat-mdc-form-field.ng-invalid.mat-focused {
      .mat-mdc-form-field-label {
        color: $shadeGreen;
      }
    }
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-underline::before {
      display: none;
    }

    .mat-mdc-form-field-ripple {
      display: none;
    }

    .mat-mdc-form-field-flex {
      border-radius: 4px 4px 4px 4px;
      //padding: 14px;
    }

    .mat-mdc-input-element {
      caret-color: $darkBlue;
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      border-top: 0;
      height: 56px;
    }
    input {
      font-size: 16px;
      max-width: 195px;
      overflow: hidden;
    }

    .mat-mdc-select-panel {
      max-width: 235px;
    }

    .mat-mdc-option {
      white-space: normal;
      line-height: 19px;
    }

    .condition-filter.condition-disable-true {
      .mat-mdc-form-field-flex {
        background-color: $polar;
      }
    }

    .condition-filter.condition-disable-false {
      .mat-mdc-form-field-flex {
        background-color: $white;
      }
    }

    .conditions-root-container.conditions-only {
      .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    .condition-filter.condition-disable-true {
      .mat-mdc-form-field-label {
        color: $darkBlue;
      }
    }

    .mat-mdc-form-field.mat-mdc-form-field-has-label.ng-untouched.mat-mdc-form-field-should-float.mat-focused {
      .mat-mdc-form-field-infix {
        .mat-mdc-form-field-label {
          color: $shadeGreen;
        }
      }
    }
  }
}
