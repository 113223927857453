@import '../../../styles/variables.scss';

#ahn-findcare {
  .language-spoken {
    mat-form-field {
      width: 100%;
    }

    .align-arrow-right {
      position: absolute;
      right: 0;
      top: 16px;
      cursor: pointer;
    }

    input.mat-mdc-input-element {
      margin-top: 16px;
    }

    input {
      font-size: 16px;
      max-width: 195px;
      overflow: hidden;
    }
    mat-label {
      color: #0009;
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: #00000061;
    }
  }
}
