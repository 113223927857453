@import './variables.scss';
@import './ahn-base-css.scss';

.ahn-provider-text {
  color: $white;
  font-size: 15px;
  white-space: nowrap;
  @include roboto-bold;
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.background-ahn-gray {
  background-color: $ahnGray;
}

.background-polar {
  background-color: $polar;
}

.bottom-border {
  border-bottom: 1px solid $lightGrey;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.center-text {
  text-align: center;
}

.margin-left {
  margin-left: 20px;
}

.max-width {
  width: 88%;
}

.max-height {
  height: 100%;
}

.bump-up {
  margin-top: -10px;
}

.universal-padding {
  padding: 1rem;
}

.universal-pointer {
  cursor: pointer;
}

#print-share .share-dropdown {
  top: 0;
  right: -1px;
}

.mat-button-toggle-group-appearance-standard {
  height: 3.1rem;
}

.extend-autocomplete {
  @media screen and (min-width: 769px) {
    width: 760px;
  }

  @media screen and (max-width: 768px) {
    width: 500px;
  }

  @media screen and (max-width: 414px) {
    width: 300px;
  }
}

.sofia-pro-semi-bold {
  @include sofia-pro-semi-bold;
}

.roboto-bold {
  @include roboto-bold;
}

.roboto-regular {
  @include roboto-regular;
}
.roboto-semi-bold {
  @include roboto-semi-bold;
}

.persistent-bar {
  padding-bottom: 30px;
  background-color: $ahn25MistGreen;
}

a[href^='tel:'] {
  color: $splashBlue;
  text-decoration: none;
}

//Styles for google address autocomplete used in location filter
.address-autocomplete-picklist {
  margin-top: 13px;
  margin-left: -10px;
  min-width: 240px;
}

.pac-container {
  margin-top: 13px;
  margin-left: -10px;
  min-width: 240px;
}

.pac-matched {
  font-size: 15px;
}

.pac-item {
  border: none;
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  white-space: normal;
}

.pac-item-query {
  font-size: 15px;
}

.pac-icon {
  display: none;
}

.hidden {
  display: none;
}

@media screen and (max-width: $tablet-max-width) {
  .remove-on-tablet {
    display: none;
  }

  .map-container {
    width: auto !important;
  }
}

@media screen and (max-width: $tablet-min-width) {
  .remove-on-mobile {
    display: none;
  }
}

span.mat-expansion-indicator::after {
  padding: 4px;
  color: #003963;
}

.ahn-global-footer span.mat-expansion-indicator::after {
  color: $ahnGray;
}

.ahn-global-footer .mat-expansion-panel-body {
  @media screen and (max-width: $tablet-min-width) {
    display: flex;
    flex-direction: column;
  }
}

div.custom-tooltip-clear {
  color: $white;
  font-size: 15px;
  font-weight: 500;
  background: $black;
  width: 40px;
  text-align: center;
  overflow: visible;
  border: 0.5px solid $black;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: calc(#{$clear-arrow-size} * -2);
    right: calc(#{$clear-arrow-size} + 37%);
    width: 0;
    height: 0;
    border: solid transparent;
  }

  &:after {
    border-bottom-color: $black;
    border-width: calc(#{$clear-arrow-size} + #{$clear-arrow-border-width});
    margin-right: calc((#{$clear-arrow-size} + #{$clear-arrow-border-width}) * -1);
  }

  &:before {
    border-bottom-color: $black;
    border-width: $clear-arrow-size;
    margin-right: -$clear-arrow-size;
  }
}

.custom-tool-tip-show-only-filter.mat-mdc-tooltip {
  color: $darkBlue;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  @include roboto-regular;
  margin-top: 4px;
  width: 256px;
  position: relative;
  right: 20px;
}

.mdc-form-field > label {
  white-space: break-spaces;
  color: #001446;
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.provider-detail-map {
  div.google-map-ahn {
    padding-left: 0;
  }
}

hr {
  border: none;
  height: 1px;
  color: $lightGrey;
  background-color: $lightGrey;
}
//Material Tabnav Overrides for Provider

.mat-mdc-tab-group > mat-tab-header {
  box-shadow: 0px 2px 2px 0px $shadowGrey;
  --mat-tab-header-label-text-font: sofia-pro, sans-serif;
  --mat-tab-header-label-text-weight: 600;
  --mat-tab-header-label-text-size: 20px;
  --mat-tab-header-label-text-style: normal;
  --mat-tab-header-label-text-line-height: 30px;
  --mdc-secondary-navigation-tab-container-height: 54px;

  .mdc-tab__text-label {
    letter-spacing: -0.4px;
    gap: 10px;
  }
}

.mat-mdc-tab-group, .mat-mdc-tab-header, .mat-mdc-tab-labels, .mat-mdc-tab-label-container, .mat-mdc-tab-body-wrapper, .mat-mdc-tab-body, .mat-mdc-tab-list, .mat-mdc-tab-body-content, .mdc-tab__text-label {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab-label-container{
  max-width: 800px;
  padding: 0px 60px;
  overflow: hidden;

}

.mat-mdc-tab-body, .mat-mdc-tab-body-content {
  flex-direction: column;
  max-width: 780px;

}
.mat-mdc-tab-body{
  display: flex !important;
}
.mat-mdc-tab-body-content{
  padding: 40px 0px 80px 0px;
  align-items: flex-start;
  gap: 24px;
}
.gm-ui-hover-effect{
  padding-top: 25px;
}
.gm-style .gm-style-iw {
  max-width:314px !important;
  max-height:199px !important;
}
.gm-style .gm-style-iw-c {
  flex-direction: row-reverse;
}
.gm-style-iw-chr{
  padding-top: 3px;
  position: absolute;
}
.gm-style div[aria-hidden="true"] {
  font-size: 10px !important;
}
@media (max-width: $tablet-min-width){
  .mat-mdc-tab-body, .mat-mdc-tab-body-content {
  padding: 0px 30px 30px !important;
  }
  .mat-mdc-tab-label-container {
    padding: 0;
  }
  .mat-mdc-tab-labels{
    padding-left: 60px;
  }
}
@media (max-width: 500px){
  .mat-mdc-tab-labels{
    justify-content: flex-start;
    padding-left: 0;
  }
}

